import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Dimmer,
  Loader,
  Divider,
  Dropdown,
} from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import './App.css';
import { useReactPWAInstall } from 'react-pwa-install';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const hccOptions = [{'key': 'HCC1', 'text': 'HIV/AIDS', 'value': 'HCC1'},
{'key': 'HCC2', 'text': 'Septicemia, Sepsis, Systemic Inflammatory Response Syndrome/Shock', 'value': 'HCC2'},
{'key': 'HCC6', 'text': 'Opportunistic Infections', 'value': 'HCC6'},
{'key': 'HCC8', 'text': 'Metastatic Cancer and Acute Leukemia', 'value': 'HCC8'},
{'key': 'HCC9', 'text': 'Lung and Other Severe Cancers', 'value': 'HCC9'},
{'key': 'HCC10', 'text': 'Lymphoma and Other Cancers', 'value': 'HCC10'},
{'key': 'HCC11', 'text': 'Colorectal, Bladder, and Other Cancers', 'value': 'HCC11'},
{'key': 'HCC12', 'text': 'Breast, Prostate, and Other Cancers and Tumors', 'value': 'HCC12'},
{'key': 'HCC17', 'text': 'Diabetes with Acute Complications', 'value': 'HCC17'},
{'key': 'HCC18', 'text': 'Diabetes with Chronic Complications', 'value': 'HCC18'},
{'key': 'HCC19', 'text': 'Diabetes without Complication', 'value': 'HCC19'},
{'key': 'HCC21', 'text': 'Protein-Calorie Malnutrition', 'value': 'HCC21'},
{'key': 'HCC22', 'text': 'Morbid Obesity', 'value': 'HCC22'},
{'key': 'HCC23', 'text': 'Other Significant Endocrine and Metabolic Disorders', 'value': 'HCC23'},
{'key': 'HCC27', 'text': 'End-Stage Liver Disease', 'value': 'HCC27'},
{'key': 'HCC28', 'text': 'Cirrhosis of Liver', 'value': 'HCC28'},
{'key': 'HCC29', 'text': 'Chronic Hepatitis', 'value': 'HCC29'},
{'key': 'HCC33', 'text': 'Intestinal Obstruction/Perforation', 'value': 'HCC33'},
{'key': 'HCC34', 'text': 'Chronic Pancreatitis', 'value': 'HCC34'},
{'key': 'HCC35', 'text': 'Inflammatory Bowel Disease', 'value': 'HCC35'},
{'key': 'HCC39', 'text': 'Bone/Joint/Muscle Infections/Necrosis', 'value': 'HCC39'},
{'key': 'HCC40', 'text': 'Rheumatoid Arthritis and Inflammatory Connective Tissue Disease', 'value': 'HCC40'},
{'key': 'HCC46', 'text': 'Severe Hematological Disorders', 'value': 'HCC46'},
{'key': 'HCC47', 'text': 'Disorders of Immunity', 'value': 'HCC47'},
{'key': 'HCC48', 'text': 'Coagulation Defects and Other Specified Hematological Disorders', 'value': 'HCC48'},
{'key': 'HCC51', 'text': 'Dementia With Complications', 'value': 'HCC51'},
{'key': 'HCC52', 'text': 'Dementia Without Complication', 'value': 'HCC52'},
{'key': 'HCC54', 'text': 'Substance Use with Psychotic Complications', 'value': 'HCC54'},
{'key': 'HCC55', 'text': 'Substance Use Disorder, Moderate/Severe, or Substance Use with Complications', 'value': 'HCC55'},
{'key': 'HCC56', 'text': 'Substance Use Disorder, Mild, Except Alcohol and Cannabis', 'value': 'HCC56'},
{'key': 'HCC57', 'text': 'Schizophrenia', 'value': 'HCC57'},
{'key': 'HCC58', 'text': 'Reactive and Unspecified Psychosis', 'value': 'HCC58'},
{'key': 'HCC59', 'text': 'Major Depressive, Bipolar, and Paranoid Disorders', 'value': 'HCC59'},
{'key': 'HCC60', 'text': 'Personality Disorders', 'value': 'HCC60'},
{'key': 'HCC70', 'text': 'Quadriplegia', 'value': 'HCC70'},
{'key': 'HCC71', 'text': 'Paraplegia', 'value': 'HCC71'},
{'key': 'HCC72', 'text': 'Spinal Cord Disorders/Injuries', 'value': 'HCC72'},
{'key': 'HCC73', 'text': 'Amyotrophic Lateral Sclerosis and Other Motor Neuron Disease', 'value': 'HCC73'},
{'key': 'HCC74', 'text': 'Cerebral Palsy', 'value': 'HCC74'},
{'key': 'HCC75', 'text': 'Myasthenia Gravis/Myoneural Disorders and Guillain-Barre Syndrome/Inflammatory and Toxic Neuropathy', 'value': 'HCC75'},
{'key': 'HCC76', 'text': 'Muscular Dystrophy', 'value': 'HCC76'},
{'key': 'HCC77', 'text': 'Multiple Sclerosis', 'value': 'HCC77'},
{'key': 'HCC78', 'text': "Parkinson's and Huntington's Diseases", 'value': 'HCC78'},
{'key': 'HCC79', 'text': 'Seizure Disorders and Convulsions', 'value': 'HCC79'},
{'key': 'HCC80', 'text': 'Coma, Brain Compression/Anoxic Damage', 'value': 'HCC80'},
{'key': 'HCC82', 'text': 'Respirator Dependence/Tracheostomy Status', 'value': 'HCC82'},
{'key': 'HCC83', 'text': 'Respiratory Arrest', 'value': 'HCC83'},
{'key': 'HCC84', 'text': 'Cardio-Respiratory Failure and Shock', 'value': 'HCC84'},
{'key': 'HCC85', 'text': 'Congestive Heart Failure', 'value': 'HCC85'},
{'key': 'HCC86', 'text': 'Acute Myocardial Infarction', 'value': 'HCC86'},
{'key': 'HCC87', 'text': 'Unstable Angina and Other Acute Ischemic Heart Disease', 'value': 'HCC87'},
{'key': 'HCC88', 'text': 'Angina Pectoris', 'value': 'HCC88'},
{'key': 'HCC96', 'text': 'Specified Heart Arrhythmias', 'value': 'HCC96'},
{'key': 'HCC99', 'text': 'Intracranial Hemorrhage', 'value': 'HCC99'},
{'key': 'HCC100', 'text': 'Ischemic or Unspecified Stroke', 'value': 'HCC100'},
{'key': 'HCC103', 'text': 'Hemiplegia/Hemiparesis', 'value': 'HCC103'},
{'key': 'HCC104', 'text': 'Monoplegia, Other Paralytic Syndromes', 'value': 'HCC104'},
{'key': 'HCC106', 'text': 'Atherosclerosis of the Extremities with Ulceration or Gangrene', 'value': 'HCC106'},
{'key': 'HCC107', 'text': 'Vascular Disease with Complications', 'value': 'HCC107'},
{'key': 'HCC108', 'text': 'Vascular Disease', 'value': 'HCC108'},
{'key': 'HCC110', 'text': 'Cystic Fibrosis', 'value': 'HCC110'},
{'key': 'HCC111', 'text': 'Chronic Obstructive Pulmonary Disease', 'value': 'HCC111'},
{'key': 'HCC112', 'text': 'Fibrosis of Lung and Other Chronic Lung Disorders', 'value': 'HCC112'},
{'key': 'HCC114', 'text': 'Aspiration and Specified Bacterial Pneumonias', 'value': 'HCC114'},
{'key': 'HCC115', 'text': 'Pneumococcal Pneumonia, Empyema, Lung Abscess', 'value': 'HCC115'},
{'key': 'HCC122', 'text': 'Proliferative Diabetic Retinopathy and Vitreous Hemorrhage', 'value': 'HCC122'},
{'key': 'HCC124', 'text': 'Exudative Macular Degeneration', 'value': 'HCC124'},
{'key': 'HCC134', 'text': 'Dialysis Status', 'value': 'HCC134'},
{'key': 'HCC135', 'text': 'Acute Renal Failure', 'value': 'HCC135'},
{'key': 'HCC136', 'text': 'Chronic Kidney Disease, Stage 5', 'value': 'HCC136'},
{'key': 'HCC137', 'text': 'Chronic Kidney Disease, Severe (Stage 4)', 'value': 'HCC137'},
{'key': 'HCC138', 'text': 'Chronic Kidney Disease, Moderate (Stage 3)', 'value': 'HCC138'},
{'key': 'HCC157', 'text': 'Pressure Ulcer of Skin with Necrosis Through to Muscle, Tendon, or Bone', 'value': 'HCC157'},
{'key': 'HCC158', 'text': 'Pressure Ulcer of Skin with Full Thickness Skin Loss', 'value': 'HCC158'},
{'key': 'HCC159', 'text': 'Pressure Ulcer of Skin with Partial Thickness Skin Loss', 'value': 'HCC159'},
{'key': 'HCC161', 'text': 'Chronic Ulcer of Skin, Except Pressure', 'value': 'HCC161'},
{'key': 'HCC162', 'text': 'Severe Skin Burn or Condition', 'value': 'HCC162'},
{'key': 'HCC166', 'text': 'Severe Head Injury', 'value': 'HCC166'},
{'key': 'HCC167', 'text': 'Major Head Injury', 'value': 'HCC167'},
{'key': 'HCC169', 'text': 'Vertebral Fractures without Spinal Cord Injury', 'value': 'HCC169'},
{'key': 'HCC170', 'text': 'Hip Fracture/Dislocation', 'value': 'HCC170'},
{'key': 'HCC173', 'text': 'Traumatic Amputations and Complications', 'value': 'HCC173'},
{'key': 'HCC176', 'text': 'Complications of Specified Implanted Device or Graft', 'value': 'HCC176'},
{'key': 'HCC186', 'text': 'Major Organ Transplant or Replacement Status', 'value': 'HCC186'},
{'key': 'HCC188', 'text': 'Artificial Openings for Feeding or Elimination', 'value': 'HCC188'},
{'key': 'HCC189', 'text': 'Amputation Status, Lower Limb/Amputation Complications', 'value': 'HCC189'}];

const CheckIn = (props) => {
  let query = useQuery();
  const [memberId, setMemberId] = useState(query.get("mid") || "");
  const [hccToRemove, setHccToRemove] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [completed, setCompleted] = useState(false);
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  let history = useHistory();

  const handleSubmit = async (event) => {
    setInProcess(true);
    console.log({query: memberId, 'hccToRemove': hccToRemove});
    /*
    const rawResponse = await fetch('https://data.mongodb-api.com/app/awv-simple-checkin-hcopc/endpoint/complete_awv', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({query: memberId, 'hccToRemove': hccToRemove})
    });
    */
    setInProcess(false);
    setCompleted(true);
  };

  const handleInstall = () => {
    pwaInstall({
      title: 'Install Patient Portal',
      logo: 'android-chrome-192x192.png',
      features: (
        <ul>
          <li>No Scan and Fax</li>
          <li>Easy Submit</li>
        </ul>
      ),
      description: 'This app helps you keep track of your health!',
    })
      .then(() =>
        alert(
          'App installed successfully or instructions for install shown',
        ),
      )
      .catch(() => alert('User opted out from installing'));
  };

  return (
    <div
      style={{
        backgroundImage: 'url("images/login-bg.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Dimmer inverted active={inProcess}>
        <Loader />
      </Dimmer>

      <Grid
        textAlign="center"
        style={{
          height: '100vh',
          margin: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{
            maxWidth: 450,
            padding: '3em',
          }}
          className="container demo animated"
        >
          <Header
            as="h1"
            icon
            textAlign="center"
            style={{ color: '#fff' }}
          >
            <Header.Content>
              Enter the Member ID and click Submit!
              <Header.Subheader style={{ color: '#fff' }}>
                For the conditions no longer present, please use the dropdown menu below to let us know.
                We will remove those conditions in the next cycle.
              </Header.Subheader>
            </Header.Content>
          </Header>



          <Form size="large">

            <Form.Input
              fluid
              icon="user"
              type="text"
              iconPosition="left"
              placeholder="MBI123456789"
              value={memberId}
              onChange={(event) => {
                let value = event.target.value;
                  setMemberId(value);
              }}
            />
            <Dropdown 
              placeholder='Conditions to Remove' 
              fluid 
              multiple 
              search 
              selection 
              options={hccOptions} 
              onChange={(event, {value}) => {
                setHccToRemove(value)
              }}
            />
            <br/>
            {!completed && <Button
              //style={{backgroundColor: "#335eea", color: "#fff"}}
              //color="green"
              primary
              fluid
              disabled={memberId.length < 8}
              size="large"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>}
            {completed && <Header
              as="h1"
              icon
              textAlign="center"
              style={{ color: '#fff' }}
              >
              Thanks for completing the AWV!
              </Header>}
            {supported() && !isInstalled() && (
              <Button
                color="teal"
                basic
                fluid
                size="large"
                onClick={handleInstall}
              >
                Install App
              </Button>
            )}
          </Form>

        </Grid.Column>
      </Grid>
    </div>
  );
};

export default CheckIn;
